/* eslint-disable prettier/prettier */
import Web3 from 'web3';

import { AbiItem } from 'web3-utils';
import { Contract } from 'web3-eth-contract';
import * as Sentry from '@sentry/nextjs';

import BigNumber from 'bignumber.js';
import NftArtifact from './contracts/NFT.json';
import AIRTContract from './contracts/AIRTContract.json';
import StakingContract from './contracts/StakingContract.json';
import PaymentBridgeContract from './contracts/PaymentBridgeContract.json';
import AirnftMarketV2Contract from './contracts/AirnftMarketV2Contract.json';
import {
  CancelNftListing,
  LaunchpadPackage,
  MakeOfferListing,
  NftIDUpdate,
  NFTListing,
  NftListingUpdate,
  NftOfferAcceptance,
  NftOfferUpdate,
  NftPriceUpdate,
  NftSaleEvent,
  SupportedChains,
  UpdateNftListingPrice,
} from '../models/NftModel';
import { configService } from '../services/ConfigService';
import { chainEnvConfig } from '../../config/chainsConfig';
import {
  ApproveEvent,
  PaymentData,
  RewardPaidEvent,
  StakingEvent,
  WithdrawEvent,
} from '../models/GeneralModels';
import { PaymentType, TOKEN_TYPES, LISTING_TYPES } from '../constants';

export interface SignResult {
  signature: string;
  data: string;
}

export class Web3Service {
  public web3Instance: Web3;

  constructor(web3Instance: Web3) {
    this.web3Instance = web3Instance;
  }

  private getFeePerGas() {
    if (!window?.ethereum?.isTrust) {
      return {
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      };
    }
    return {};
  }

  public async sendAIRTTokens(
    nftName: string,
    minter: string,
    storageFee: string,
  ) {
    const deployerAddress = configService.getDeployerAddress();

    const transactionReceipt = await this.getContractAIRTTokenAbi()
      .methods.transfer(deployerAddress, storageFee)
      .send({
        from: minter,
        ...this.getFeePerGas(),
      });

    // eslint-disable-next-line no-console
    console.log('transactionReceipt', transactionReceipt);

    if (transactionReceipt && transactionReceipt.status) {
      return transactionReceipt;
    }
    return null;
  }

  public async sendNFTUpvotes(
    nftTokenID: string,
    upvoterAddress: string,
    amount: string,
    upvotesCount: number,
  ) {
    const web3 = this.web3Instance;
    const receiverAddress = configService.getUpvoteFundsReceiverAddress();
    const txData = web3.utils.utf8ToHex(`ID:${nftTokenID}_V:${upvotesCount}`);

    const transactionReceipt = await web3.eth.sendTransaction({
      from: upvoterAddress,
      to: receiverAddress,
      value: amount,
      data: txData,
      gas: 60_000,
      ...this.getFeePerGas(),
    });

    if (transactionReceipt && transactionReceipt.status) {
      return transactionReceipt;
    }
    return null;
  }

  public async sendMintFeeTransaction(
    nftName: string,
    minter: string,
    storageFee: string,
    fileSizeInMb: number,
    chain: SupportedChains = 'bsc',
  ) {
    const web3 = this.web3Instance;
    const deployerAddress = configService.getDeployerAddress(chain);
    const txData = web3.utils.utf8ToHex(`${nftName}_${fileSizeInMb}`);
    const gas = configService.getGasLimitAdjusted(chain, 60_000);

    const transactionReceipt = await web3.eth.sendTransaction({
      from: minter,
      to: deployerAddress,
      value: storageFee,
      data: txData,
      gas,
      ...this.getFeePerGas(),
    });

    if (transactionReceipt && transactionReceipt.status) {
      return transactionReceipt;
    }
    return null;
  }

  public async sendStorageFeeTransaction(
    minter: string,
    storageFee: string,
    chain: SupportedChains = 'bsc',
  ) {
    try {
      const deployerAddress = configService.getDeployerAddress(chain);

      const paymentResult = await this.sendPayment({
        paymentType: PaymentType.STORAGE_FEE,
        receiverAddress: deployerAddress,
        amount: storageFee,
        senderAddress: minter,
        chain,
      });

      if (paymentResult.events.SendPayment) {
        return paymentResult.events.SendPayment;
      }
      return null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error sendStorageFee', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async sendPayment({
    paymentType,
    receiverAddress,
    senderAddress,
    amount,
    chain = 'bsc',
  }: PaymentData) {
    try {
      const paymentBridgeContract = this.getPaymentBridgeContract(chain);
      const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

      const paymentResult = await paymentBridgeContract.methods
        .sendPayment(receiverAddress, paymentType)
        .send({
          from: senderAddress,
          value: amount,
          gasLimit,
          ...this.getFeePerGas(),
        });

      return paymentResult;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error sendPayment', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async sendPaymentInAIRT(
    paymentType,
    receiverAddress,
    senderAddress,
    amount,
    chain: SupportedChains = 'bsc',
  ) {
    try {
      const web3 = this.web3Instance;
      const paymentBridgeContract = this.getPaymentBridgeContract(chain);
      const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);
      const feeAmount = web3.utils.toWei(amount, 'ether');
      const tokenContractAddress =
        chainEnvConfig[chain].airtTokenContactAddress;

      const paymentResult = await paymentBridgeContract.methods
        .sendERC20TokenPayment(
          tokenContractAddress,
          receiverAddress,
          feeAmount,
          paymentType,
        )
        .send({
          from: senderAddress,
          gasLimit,
          ...this.getFeePerGas(),
        });

      return paymentResult;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('sendERC20TokenPayment', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async getTokenAllowance(
    owner,
    chain: SupportedChains = 'bsc',
  ): Promise<number> {
    try {
      const tokenContract = this.getContractAIRTTokenAbi(chain);
      const paymentBridgeContract = this.getPaymentBridgeContractAddress(chain);

      const allowance = await tokenContract.methods
        .allowance(owner, paymentBridgeContract)
        .call();

      const allowanceAmount = new BigNumber(
        this.web3Instance.utils.fromWei(allowance, 'ether'),
      ).toNumber();

      return allowanceAmount;
    } catch (error) {
      return 0;
    }
  }

  public async payFeeInAIRT(
    data: string,
    creator: string,
    customFeeAmount: string,
    chain: SupportedChains = 'bsc',
  ) {
    try {
      const web3 = this.web3Instance;
      const deployerAddress = configService.getDeployerAddress(chain);
      const txData = web3.utils.utf8ToHex(data);
      const gas = configService.getGasLimitAdjusted(chain, 200_000);

      const transactionValue = web3.utils.toWei(customFeeAmount, 'ether');

      const transactionReceipt = await this.getContractAIRTTokenAbi(chain)
        .methods.transfer(deployerAddress, transactionValue)
        .send({
          from: creator,
          data: txData,
          gas,
          ...this.getFeePerGas(),
        });

      if (transactionReceipt && transactionReceipt.status) {
        return transactionReceipt;
      }
      return null;
    } catch (e) {
      console.log('error payFeeInAIRT', e);
      return null;
    }
  }

  public async buyLaunchpadPackage(
    buyerAddress: string,
    launchpadPackage: LaunchpadPackage,
    currentBNBUSDPrice: string,
  ) {
    try {
      const fundsReceiverAddress = configService.getLaunchpadFundsReceiverAddress();

      const launchpadPriceInBNB = new BigNumber(launchpadPackage.unitPrice)
        .div(currentBNBUSDPrice)
        .toFixed(4);

      return await this.sendFunds(
        launchpadPriceInBNB,
        buyerAddress,
        fundsReceiverAddress,
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('error buyLaunchpadPackage', e);
      return null;
    }
  }

  public async sendFunds(
    amount: string,
    originAddress: string,
    receiverAddress: string,
  ) {
    const web3 = this.web3Instance;

    const transactionValue = web3.utils.toWei(amount, 'ether');

    const transactionReceipt = await web3.eth.sendTransaction({
      from: originAddress,
      to: receiverAddress,
      value: transactionValue,
      data: '',
      gas: 60_000,
      ...this.getFeePerGas(),
    });

    if (transactionReceipt && transactionReceipt.status) {
      return transactionReceipt;
    }
    return null;
  }

  public async buyNftToken(
    nftID: string,
    buyerAddress: string,
    nftPrice: string,
    chain: SupportedChains = 'bsc',
  ): Promise<NftSaleEvent> {
    try {
      const nftContract = this.getContractAbi(chain);
      const web3 = this.web3Instance;
      const nftPriceInUnit = web3.utils.toWei(nftPrice, 'ether');
      const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

      const buyResult = await nftContract.methods.buy(nftID).send({
        from: buyerAddress,
        value: nftPriceInUnit,
        gasLimit,
        ...this.getFeePerGas(),
      });

      const purchaseEvent = buyResult.events.Purchase.returnValues;

      const nftSoldPrice = web3.utils.fromWei(purchaseEvent.price, 'ether');

      return {
        newOwnerAddress: purchaseEvent.newOwner,
        sellerAddress: purchaseEvent.previousOwner,
        nftIDSold: purchaseEvent.nftID,
        nftSoldAtPrice: nftSoldPrice,
        transactionHash: buyResult.transactionHash,
        chain,
      };
    } catch (e) {
      console.log('error buyNftToken', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async mintToken(
    ownerAddress: string,
    baseId: string,
    price: string,
    chain: SupportedChains = 'bsc',
  ): Promise<NftIDUpdate> {
    const uri = this.getTokenUri(baseId);
    const nftContract = this.getContractAbi(chain);

    try {
      const nftPrice = this.web3Instance.utils.toWei(price, 'ether');
      const mintResult = await nftContract.methods
        .mint(uri, ownerAddress, nftPrice)
        .send({
          from: ownerAddress,
          gasLimit: configService.getGasLimitAdjusted(chain, 320_000),
          ...this.getFeePerGas(),
        });

      const { nftID } = mintResult.events.Minted.returnValues;

      const data = {
        tokenID: nftID,
        baseID: baseId,
        mintTransactionHash: mintResult.transactionHash,
        chain,
      };

      return { ...data };
    } catch (e) {
      console.log('Error minting ...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async updateNftPrice(
    ownerAddress: string,
    baseId: string,
    tokenId: string,
    newNftProposedPrice: string,
    chain: SupportedChains = 'bsc',
  ): Promise<NftPriceUpdate> {
    const nftContract = this.getContractAbi(chain);

    try {
      const web3 = this.web3Instance;
      const nftPriceProposedPriceInWei = web3.utils.toWei(
        newNftProposedPrice,
        'ether',
      );

      const priceUpdateResult = await nftContract.methods
        .updatePrice(tokenId, nftPriceProposedPriceInWei)
        .send({
          from: ownerAddress,
          gasLimit: configService.getGasLimitAdjusted(chain, 60_000),
          ...this.getFeePerGas(),
        });

      const {
        newPrice,
        nftID,
      } = priceUpdateResult.events.PriceUpdate.returnValues;
      const newNftPriceConvertedToEth = web3.utils.fromWei(newPrice, 'ether');

      return {
        newPrice: newNftPriceConvertedToEth,
        baseID: baseId,
        priceUpdateTxHash: priceUpdateResult.transactionHash,
        nftID,
      };
    } catch (e) {
      console.log('Error updating price ...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async setListingStatus({
    ownerAddress,
    baseId,
    tokenId,
    newListingStatus,
    chain = 'bsc',
    tokenAddress = chainEnvConfig[chain]?.mintContractAddress,
  }: {
    ownerAddress: string;
    baseId: string;
    tokenId: string;
    newListingStatus: boolean;
    chain: SupportedChains;
    tokenAddress: string;
  }): Promise<NftListingUpdate> {
    const nftContract = this.getContractAbi(chain, tokenAddress);

    try {
      const listingStatusUpdate = await nftContract.methods
        .updateListingStatus(tokenId, newListingStatus)
        .send({
          from: ownerAddress,
          gasLimit: configService.getGasLimitAdjusted(chain, 100_000),
          ...this.getFeePerGas(),
        });

      const {
        isListed,
        nftID,
      } = listingStatusUpdate.events.NftListStatus.returnValues;

      return {
        setIsListed: isListed,
        baseID: baseId,
        listingUpdateTxHash: listingStatusUpdate.transactionHash,
        nftID,
        userAddress: ownerAddress,
      };
    } catch (e) {
      console.log('Error setListingStatus ...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  private getContractAbi(
    chain: SupportedChains = 'bsc',
    tokenAddress?: string,
  ): Contract {
    const contractABI = NftArtifact.abi as AbiItem[];
    const contractAddress = this.getContractAddress(chain);
    return new this.web3Instance.eth.Contract(
      contractABI,
      tokenAddress || contractAddress,
    );
  }

  private getContractAIRTTokenAbi(chain: SupportedChains = 'bsc'): Contract {
    const contractABI = AIRTContract.abi as AbiItem[];
    const contractAddress = this.getAIRTTokenContractAddress(chain);
    return new this.web3Instance.eth.Contract(contractABI, contractAddress);
  }

  private getContractStakingAbi(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Contract {
    const contractABI = StakingContract.abi as AbiItem[];
    const contractAddress = this.getStakingContractAddress(chain, pool);
    return new this.web3Instance.eth.Contract(contractABI, contractAddress);
  }

  private getContractTokenStakingAbi(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Contract {
    const contractABI = AIRTContract.abi as AbiItem[];
    const contractAddress =
      chainEnvConfig[chain].stakingContractAddresses[pool].contractAddress ||
      chainEnvConfig.bsc.stakingContractAddresses.AIRT.contractAddress;
    return new this.web3Instance.eth.Contract(contractABI, contractAddress);
  }

  private getContractAddress(chain: SupportedChains = 'bsc') {
    return (
      chainEnvConfig[chain].mintContractAddress ||
      chainEnvConfig.bsc.mintContractAddress
    );
  }

  private getAIRTTokenContractAddress(chain: SupportedChains = 'bsc') {
    return (
      chainEnvConfig[chain].airtTokenContactAddress ||
      chainEnvConfig.bsc.airtTokenContactAddress
    );
  }

  private getStakingContractAddress(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ) {
    return (
      chainEnvConfig[chain].stakingContractAddresses[pool].contractAddress ||
      chainEnvConfig.bsc.stakingContractAddresses.AIRT.contractAddress
    );
  }

  private getPaymentBridgeContractAddress(chain: SupportedChains = 'bsc') {
    return (
      chainEnvConfig[chain].paymentBridgeContractAddress ||
      chainEnvConfig.bsc.paymentBridgeContractAddress
    );
  }

  public getTokenUri(baseID: string) {
    return `${process.env.NEXT_PUBLIC_BACKEND_SERVICE_URL}/nfts/${baseID}`;
  }

  public async signPersonalMessage(
    data: string,
    address: string,
  ): Promise<SignResult> {
    try {
      const web3 = this.web3Instance;
      const hashedData = web3.utils.sha3(data);
      const signature = await web3.eth.personal.sign(hashedData, address, '');

      console.log({
        data,
        hashedData,
        signature,
      });

      return {
        signature,
        data,
      };
    } catch (e) {
      console.log('error :', e);
      return null;
    }
  }

  public async fetchAirtTokenBalance(
    address: string,
    chain: SupportedChains = 'bsc',
  ): Promise<string> {
    try {
      const balance = await this.getContractAIRTTokenAbi(chain)
        .methods.balanceOf(address)
        .call();

      return this.web3Instance.utils.fromWei(balance, 'ether');
    } catch (e) {
      console.log('Error fetchAirtTokenBalance', e);
      return '0';
    }
  }

  public async transferNFT(
    fromAddress: string,
    toAddress: string,
    nftTokenID: string,
    chain: SupportedChains = 'bsc',
  ) {
    const nftContract = this.getContractAbi(chain);

    try {
      const nftTransferResult = await nftContract.methods
        .transferFrom(fromAddress, toAddress, nftTokenID)
        .send({
          from: fromAddress,
          gasLimit: configService.getGasLimitAdjusted(chain, 250_000),
          ...this.getFeePerGas(),
        });

      const {
        tokenId,
        from,
        to,
      } = nftTransferResult.events.Transfer.returnValues;

      const data = {
        tokenID: tokenId,
        from,
        to,
        transferTransactionHash: nftTransferResult.transactionHash,
      };

      // eslint-disable-next-line no-console
      console.log('NFT transfer data', data);

      return { ...data };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Error transferNFT ...', e);
      return null;
    }
  }

  public async approve(
    spender: string,
    amount: string,
    owner: string,
    chain: SupportedChains = 'bsc',
  ): Promise<ApproveEvent> {
    try {
      const tokenContract = this.getContractAIRTTokenAbi(chain);
      const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

      const web3 = this.web3Instance;
      const amountWei = web3.utils.toWei(amount, 'ether');

      const approveResult = await tokenContract.methods
        .approve(spender, amountWei)
        .send({
          from: owner,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const approveEvent = approveResult.events.Approval.returnValues;

      return approveEvent;
    } catch (e) {
      console.log('error staking', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async onStake(
    amount: string,
    exitTime: string,
    receiverAddress: string,
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<StakingEvent> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);
      const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

      const web3 = this.web3Instance;
      const amountWei = web3.utils.toWei(amount, 'ether');

      const stakeResult = await stakingContract.methods
        .stake(amountWei, exitTime)
        .send({
          from: receiverAddress,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const stakeEvent = stakeResult.events.Staked.returnValues;

      console.log({ stakeEvent });

      return {
        user: stakeEvent.user,
        amount: stakeEvent.amount,
      };
    } catch (e) {
      console.log('error staking', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async onExit(
    receiverAddress: string,
    amount,
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<WithdrawEvent> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);
      const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

      const web3 = this.web3Instance;
      const amountWei = web3.utils.toWei(amount, 'ether');

      const exitResult = await stakingContract.methods
        .withdraw(amountWei)
        .send({
          from: receiverAddress,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const exitEvent = exitResult.events.Withdrawn.returnValues;

      return {
        user: exitEvent.user,
        amount: exitEvent.amount,
      };
    } catch (e) {
      console.log('error when exit', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async onClaim(
    receiverAddress: string,
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<RewardPaidEvent> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);
      const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

      const claimResult = await stakingContract.methods.getReward().send({
        from: receiverAddress,
        gasLimit,
        ...this.getFeePerGas(),
      });

      const claimEvent = claimResult.events.RewardPaid.returnValues;

      return {
        user: claimEvent.user,
        reward: claimEvent.reward,
      };
    } catch (e) {
      console.log('error when claim', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async getStakedBalance(
    address?: string,
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);
      const stakingContractAddress = this.getStakingContractAddress(
        chain,
        pool,
      );

      const balance = await stakingContract.methods
        .balanceOf(address || stakingContractAddress)
        .call();

      const balanceAmount = this.web3Instance.utils.fromWei(balance, 'ether');

      return +balanceAmount;
    } catch (error) {
      return 0;
    }
  }

  public async getTotalStakedBalance(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);
      const balance = await stakingContract.methods.totalSupply().call();
      const balanceAmount = this.web3Instance.utils.fromWei(balance, 'ether');

      return +balanceAmount;
    } catch (error) {
      return 0;
    }
  }

  public async getRewards(
    owner: string,
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);

      const rewards = await stakingContract.methods.earned(owner).call();

      const rewardsAmount = this.web3Instance.utils.fromWei(rewards, 'ether');

      return +rewardsAmount;
    } catch (error) {
      return 0;
    }
  }

  public async getExitTime(
    owner: string,
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);

      const exitTime = await stakingContract.methods
        .getEarliestExitTime(owner)
        .call();

      return exitTime;
    } catch (error) {
      return 0;
    }
  }

  public async getRewardAPY(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);
      const rewardsForTheEntireDuration = await stakingContract.methods
        .getRewardForDuration()
        .call();

      // console.log({ rewardsForTheEntireDuration });

      const totalTokensStaked = await stakingContract.methods
        .totalSupply()
        .call();

      // console.log({ totalTokensStaked });

      const rewardDurationInSeconds = await stakingContract.methods
        .rewardDurationInSeconds()
        .call();

      // console.log({ rewardDurationInSeconds });

      const percentage = 100;
      const yearInSeconds = 31_556_952;
      const annualizedDuration = yearInSeconds / rewardDurationInSeconds;

      const apy = new BigNumber(rewardsForTheEntireDuration)
        .div(totalTokensStaked)
        .multipliedBy(percentage)
        .multipliedBy(annualizedDuration)
        .toFixed();

      return Number(apy);
    } catch (error) {
      return 0;
    }
  }

  public async getEarlyExitPenaltyPercentage(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);

      const earlyExitPenaltyPercentage = await stakingContract.methods
        .getEarlyExitPenaltyPercentage()
        .call();

      return earlyExitPenaltyPercentage;
    } catch (error) {
      return 0;
    }
  }

  public async getEarliestExitTime(
    owner: string,
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);

      const earliestExitTime = await stakingContract.methods
        .getEarliestExitTime(owner)
        .call();

      return earliestExitTime;
    } catch (error) {
      return 0;
    }
  }

  public async getRewardDuration(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);

      const rewardDuration = await stakingContract.methods
        .rewardDurationInSeconds()
        .call();

      return rewardDuration;
    } catch (error) {
      return 0;
    }
  }

  public async getMinStakePeriodInDays(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);

      const minStakePeriod = await stakingContract.methods
        .minStakePeriodInDays()
        .call();

      return minStakePeriod;
    } catch (error) {
      return 0;
    }
  }

  public async getAllowance(
    owner,
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<number> {
    try {
      const tokenContract = this.getContractAIRTTokenAbi(chain);
      const stakingContractAddress = this.getStakingContractAddress(
        chain,
        pool,
      );

      const allowance = await tokenContract.methods
        .allowance(owner, stakingContractAddress)
        .call();

      const allowanceAmount = this.web3Instance.utils.fromWei(
        allowance,
        'ether',
      );

      return +allowanceAmount;
    } catch (error) {
      return 0;
    }
  }

  private getContract(tokenContract): Contract | any {
    const contractABI = AIRTContract.abi as AbiItem[];
    return new this.web3Instance.eth.Contract(contractABI, tokenContract);
  }

  private getPaymentBridgeContract(chain: SupportedChains): Contract {
    const contractABI = PaymentBridgeContract.abi as AbiItem[];
    const contractAddress =
      chainEnvConfig[chain]?.paymentBridgeContractAddress ||
      chainEnvConfig.bsc?.paymentBridgeContractAddress;
    return new this.web3Instance.eth.Contract(contractABI, contractAddress);
  }

  public async getTokenContractInfo(
    chain: SupportedChains = 'bsc',
    pool: string = 'AIRT',
  ): Promise<any> {
    try {
      const stakingContract = this.getContractStakingAbi(chain, pool);

      const rewardToken = await stakingContract.methods.rewardsToken().call();
      const stakingToken = await stakingContract.methods.stakingToken().call();

      const [
        rewardTokenName,
        rewardTokenSymbol,
        stakingTokenName,
        stakingTokenSymbol,
      ] = await Promise.all([
        this.getContract(rewardToken).methods.name().call(),
        this.getContract(rewardToken).methods.symbol().call(),
        this.getContract(stakingToken).methods.name().call(),
        this.getContract(stakingToken).methods.symbol().call(),
      ]);

      return {
        rewardTokenName,
        rewardTokenSymbol,
        stakingTokenName,
        stakingTokenSymbol,
      };
    } catch (error) {
      return [];
    }
  }

  private getAirnftMarketV2Contract(chain: SupportedChains = 'bsc'): Contract {
    const contractABI = AirnftMarketV2Contract.abi as AbiItem[];
    const contractAddress = chainEnvConfig[chain]?.airnftV2ContractAddress;
    return new this.web3Instance.eth.Contract(contractABI, contractAddress);
  }

  public async approveNft({
    tokenId,
    tokenAddress,
    ownerAddress,
    chain,
  }: {
    tokenId: string;
    tokenAddress?: string;
    ownerAddress: string;
    chain: SupportedChains;
  }) {
    try {
      const nftContract = this.getContractAbi(chain, tokenAddress);
      const contractAddress = chainEnvConfig[chain]?.airnftV2ContractAddress;

      const approveResult = await nftContract.methods
        .setApprovalForAll(contractAddress, true)
        .send({
          from: ownerAddress,
          gasLimit: configService.getGasLimitAdjusted(chain, 100_000),
          ...this.getFeePerGas(),
        });

      const approveEvent = approveResult.events.ApprovalForAll.returnValues;

      return approveEvent;
    } catch (e) {
      console.log('error Approve nft...', e, {
        tokenId,
        tokenAddress,
        ownerAddress,
        chain,
      });
      Sentry.captureException(e);
      return null;
    }
  }

  public async createNftListing({
    sellerAddress,
    tokenId,
    price,
    chain = 'bsc',
    tokenAddress = chainEnvConfig[chain]?.mintContractAddress,
  }: {
    sellerAddress: string;
    tokenId: string;
    price: string;
    chain: SupportedChains;
    tokenAddress: string;
  }): Promise<NFTListing> {
    const airnftMarketV2Contract = this.getAirnftMarketV2Contract(chain);
    const initialPrice = this.web3Instance.utils.toWei(price, 'ether');
    const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

    try {
      const nftListing = await airnftMarketV2Contract.methods
        .createListing(
          {
            tokenAddress,
            tokenId,
            amount: 0, // always 0 for ERC721 Token
            kind: TOKEN_TYPES.ERC721,
          },
          initialPrice,
        )
        .send({ from: sellerAddress, gasLimit, ...this.getFeePerGas() });

      const {
        invId,
        startAt,
        endAt,
      } = nftListing.events.EvInventoryCreated.returnValues;

      return {
        transactionHash: nftListing.transactionHash,
        tokenID: tokenId,
        tokenAddress,
        sellerAddress,
        listingID: +invId,
        startAt,
        endAt,
        listingType: LISTING_TYPES.fixSale,
        price: +price,
      };
    } catch (e) {
      console.log('Error CreateNFTListing...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async makeOffer(
    listingID: number,
    durationInSeconds: number = 0,
    offerAmount: string,
    buyerAddress: string,
    chain: SupportedChains = 'bsc',
  ): Promise<MakeOfferListing> {
    const airnftMarketV2Contract = this.getAirnftMarketV2Contract(chain);
    const initialPrice = this.web3Instance.utils.toWei(offerAmount, 'ether');
    const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

    try {
      const offerCreating = await airnftMarketV2Contract.methods
        .makeOffer(listingID, durationInSeconds)
        .send({
          from: buyerAddress,
          value: initialPrice,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const {
        invId,
        amount,
        seller,
        startAt,
        endAt,
      } = offerCreating.events.EvOfferCreated.returnValues;

      return {
        listingID: +invId,
        sellerAddress: seller,
        price: +this.web3Instance.utils.fromWei(amount, 'ether'),
        transactionHash: offerCreating.transactionHash,
        startAt,
        endAt,
      };
    } catch (e) {
      console.log('Error MakingOffer...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async acceptOffer({
    listingID,
    ownerAddress,
    offerorAddress,
    chain = 'bsc',
  }: {
    listingID: number;
    ownerAddress: string;
    offerorAddress: string;
    chain: SupportedChains;
  }): Promise<Partial<NftOfferAcceptance>> {
    const airnftMarketV2Contract = this.getAirnftMarketV2Contract(chain);
    const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

    try {
      const offerAcceptance = await airnftMarketV2Contract.methods
        .acceptOffer(listingID, offerorAddress)
        .send({
          from: ownerAddress,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const {
        invId,
        amount,
        seller,
      } = offerAcceptance.events.EvOfferAccepted.returnValues;

      return {
        sellerAddress: seller,
        buyerAddress: offerorAddress,
        listingID: +invId,
        offerTrxHash: offerAcceptance.transactionHash,
        price: +amount,
      };
    } catch (e) {
      console.log('Error AcceptingOffer...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async updateOffer({
    listingID,
    offerorAddress,
    offerAmount,
    chain = 'bsc',
  }: {
    listingID: number;
    offerorAddress: string;
    offerAmount: string;
    chain: SupportedChains;
  }): Promise<Partial<NftOfferUpdate>> {
    const airnftMarketV2Contract = this.getAirnftMarketV2Contract(chain);
    const initialPrice = this.web3Instance.utils.toWei(offerAmount, 'ether');
    const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

    try {
      const offerUpdate = await airnftMarketV2Contract.methods
        .updateOffer(listingID)
        .send({
          from: offerorAddress,
          value: initialPrice,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const {
        amount,
        offeror,
      } = offerUpdate.events.EvOfferUpdated.returnValues;

      return {
        buyerAddress: offeror,
        offerTrxHash: offerUpdate.transactionHash,
        price: +this.web3Instance.utils.fromWei(amount, 'ether'),
      };
    } catch (e) {
      console.log('Error UpdatingOffer...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async cancelOffer({
    listingID,
    offerorAddress,
    chain = 'bsc',
  }: {
    listingID: number;
    offerorAddress: string;
    chain: SupportedChains;
  }): Promise<any> {
    const airnftMarketV2Contract = this.getAirnftMarketV2Contract(chain);
    const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

    try {
      const offerCancelling = await airnftMarketV2Contract.methods
        .cancelOffer(listingID)
        .send({
          from: offerorAddress,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const {
        amount,
        offeror,
      } = offerCancelling.events.EvOfferCancelled.returnValues;

      return {
        buyerAddress: offeror,
        offerTrxHash: offerCancelling.transactionHash,
        price: +this.web3Instance.utils.fromWei(amount, 'ether'),
      };
    } catch (e) {
      console.log('Error CancellingOffer...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async buyV2ListedNftToken({
    listingID,
    buyerAddress,
    price,
    chain = 'bsc',
  }: {
    listingID: number;
    buyerAddress: string;
    price: string;
    chain: SupportedChains;
  }): Promise<NftSaleEvent> {
    const airnftMarketV2Contract = this.getAirnftMarketV2Contract(chain);
    const initialPrice = this.web3Instance.utils.toWei(price, 'ether');
    const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

    try {
      const buyResult = await airnftMarketV2Contract.methods
        .buy(listingID)
        .send({
          from: buyerAddress,
          value: initialPrice,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const purchaseEvent = buyResult.events.EvPurchased.returnValues;

      const nftSoldPrice = this.web3Instance.utils.fromWei(
        purchaseEvent.price,
        'ether',
      );

      return {
        newOwnerAddress: purchaseEvent.newOwner,
        sellerAddress: purchaseEvent.previousOwner,
        nftIDSold: purchaseEvent.nftID,
        nftSoldAtPrice: nftSoldPrice,
        transactionHash: buyResult.transactionHash,
        chain,
      };
    } catch (e) {
      console.log('Error CreateNFTListing...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async cancelNftListing({
    listingID,
    ownerAddress,
    chain,
  }: {
    listingID: number;
    ownerAddress: string;
    chain: SupportedChains;
  }): Promise<CancelNftListing> {
    const airnftMarketV2Contract = this.getAirnftMarketV2Contract(chain);
    const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

    try {
      const cancelResult = await airnftMarketV2Contract.methods
        .cancelFixSaleListing(listingID)
        .send({
          from: ownerAddress,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const cancelEvent = cancelResult.events.EvInventoryCancelled.returnValues;

      return {
        listingID: cancelEvent.invId,
        userAddress: cancelEvent.seller,
        cancelListingTrxHash: cancelResult.transactionHash,
      };
    } catch (e) {
      console.log('Error CancelNFTListing...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async updateNftListingPrice({
    listingID,
    ownerAddress,
    price,
    chain = 'bsc',
  }: {
    listingID: number;
    ownerAddress: string;
    price: string;
    chain: SupportedChains;
  }): Promise<UpdateNftListingPrice> {
    const airnftMarketV2Contract = this.getAirnftMarketV2Contract(chain);
    const initialPrice = this.web3Instance.utils.toWei(price, 'ether');
    const gasLimit = configService.getGasLimitAdjusted(chain, 200_000);

    try {
      const updateResult = await airnftMarketV2Contract.methods
        .updateListingPrice(listingID, initialPrice)
        .send({
          from: ownerAddress,
          gasLimit,
          ...this.getFeePerGas(),
        });

      const updateEvent =
        updateResult.events.EvInventoryPriceUpdated.returnValues;

      return {
        listingID: +updateEvent.invId,
        newPrice: +this.web3Instance.utils.fromWei(
          updateEvent.newPrice,
          'ether',
        ),
        oldPrice: +this.web3Instance.utils.fromWei(
          updateEvent.oldPrice,
          'ether',
        ),
        seller: updateEvent.seller,
        updateListingTrxHash: updateResult.transactionHash,
      };
    } catch (e) {
      console.log('Error UpdatingListingPrice...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async checkForNftApprove({
    tokenID,
    chain = 'bsc',
    tokenAddress = chainEnvConfig[chain]?.mintContractAddress,
  }: {
    tokenID: number;
    chain: SupportedChains;
    tokenAddress: string;
  }): Promise<boolean> {
    const nftContract = this.getContractAbi(chain, tokenAddress);

    try {
      const approvedContractAddress = await nftContract.methods
        .getApproved(tokenID)
        .call();

      const isApproved =
        approvedContractAddress ===
        chainEnvConfig[chain]?.airnftV2ContractAddress;

      return isApproved;
    } catch (e) {
      console.log('Error CheckingNFTApprove...', e);
      Sentry.captureException(e);
      return null;
    }
  }

  public async checkForAllNftsApprove({
    ownerAddress,
    chain = 'bsc',
    tokenAddress = chainEnvConfig[chain]?.mintContractAddress,
  }: {
    ownerAddress: string;
    chain: SupportedChains;
    tokenAddress: string;
  }): Promise<boolean> {
    const nftContract = this.getContractAbi(chain, tokenAddress);

    try {
      const approvedContractAddress = await nftContract.methods
        .isApprovedForAll(
          ownerAddress,
          chainEnvConfig[chain]?.airnftV2ContractAddress,
        )
        .call();

      return approvedContractAddress;
    } catch (e) {
      console.log('Error CheckingNFTApprove...', e);
      Sentry.captureException(e);
      return null;
    }
  }
}
